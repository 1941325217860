import axios from 'axios'

let apiUnternehmensPortal = axios.create({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
  withCredentials: true,
  baseURL: "",
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN"
})

let apiInformationsPortal = axios.create({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
  withCredentials: true,
  baseURL: "",
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN"
})

let apiDataExchange = axios.create({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
  },
  withCredentials: true,
  baseURL: "",
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN"
})

export { apiUnternehmensPortal, apiInformationsPortal, apiDataExchange }